

.chat-text-area {
  box-sizing: border-box;
  width: 100%;
  height: 56px; /* Fixed height */
  padding-right: 60px; /* Make space for the button */
  border-radius: 24px; /* Add border radius */
  padding: 0 10px; /* Ensure padding inside the TextArea */
  border: 1px solid #d9d9d9; /* Add border color */
  resize: none; /* Disable manual resizing */
  display: flex;
  align-items: center; /* Center the text vertically */
  font-size: 16px; /* Adjust font size if needed */
}

.ant-input {
  line-height: 1.5; /* Adjust line-height if needed */
}

.send-button {
  position: absolute;
  right: 10px;
  height: 36px; /* Adjust height to fit nicely */
  background-color: #1890ff;
  border-color: #1890ff;
  border-radius: 18px; /* Rounded button */
  display: flex;
  align-items: center;
  justify-content: center;
}
